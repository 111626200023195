import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const ARModal = () => {
  const data = useStaticQuery(graphql`
    query annualReports {
      allFile(
        filter: { extension: { eq: "pdf" } }
        sort: { fields: absolutePath, order: DESC }
      ) {
        edges {
          node {
            id
            relativePath
          }
        }
      }
    }
  `)

  const years = [
      "2018-2019",
      "2017-2018",
      "2016-2017",
      "2015-2016",
      "2014-2015",
      "2013-2014",
      "2012-2013",
      "2011-2012",
      "2010-2011",
      "2009-2010",
      "2008-2009"
  ]

  return (
    <>
      {/* Annual Reports Modal */}
      <a className="btn modal-trigger mb2" href="#ar-modal">
        View Our Annual Reports
      </a>

      <div id="ar-modal" className="modal">
        <div className="modal-content">
          <h4>Annual Reports</h4>
          <h4 className="page-content-important">
            We are committed to the transparency of our organization! Below, you
            will find an archive of our Annual Reports. Click to view and
            download each report.
          </h4>
          <ol className="left-align page-content">
            {data.allFile.edges.map((node, i) => (
              <a
                href={`Annual_Reports/${node.node.relativePath}`}
                target="_blank"
                rel="noopener noreferrer"
                key={node.node.id}
              >
                <li>{years[i]}</li>
              </a>
            ))}
          </ol>
        </div>
      </div>
    </>
  )
}

export default ARModal
