import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Parallax } from "react-scroll-parallax"
import Row from "./Row"

import Debbie from "./board/Debbie"
import Ang from "./board/Ang"
import Starlit from "./board/Starlit"
import Tony from "./board/Tony"
import Katie from "./board/Katie"
import Rory from "./board/Rory"
import Leo from "./board/Leo"

const Team = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "libertad.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div id="team">
      <div style={{ position: "relative" }}>
        <div
          style={{
            height: "500px",
            minWidth: "100%",
            zIndex: "-100",
            margin: "0 auto",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <Parallax y={[-25, 25]} tagOuter="div">
            <Img
              alt=" "
              fluid={data.placeholderImage.childImageSharp.fluid}
              imgStyle={{ objectFit: "cover", objectPosition: "right" }}
              style={{ minHeight: "550px" }}
            />
          </Parallax>
        </div>
        <p className="parallax-text parallax-text-3 landing-page-header no-animation">
          Team
        </p>
      </div>

      <Row addClass="white">
        <br />
        <div className="col s1 m2" />
        <div className="col s10 m8 center-align" id="who-we-are">
          <h4 className="page-content-important itl">
            Beyond the Block’s team includes professionals in education,
            business, and media, but most importantly it includes people who are
            passionate about helping kids and understanding the world!
          </h4>

          <Row>
            <Debbie />
          </Row>

          <h3 className="page-sub-head border-top">Meet Our Board of Directors</h3>

          <Row>
            <Ang />
            <Starlit />
          </Row>

          <Row>
            <Tony />
            <Katie />
          </Row>

          <Row>
            <Rory />
            <Leo />
          </Row>
        </div>
        <div className="col s1 m2"></div>
      </Row>
    </div>
  )
}

export default Team
