import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Parallax } from "react-scroll-parallax"

const Students = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "students.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          height: "500px",
          minWidth: "100%",
          zIndex: "-100",
          margin: "0 auto",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Parallax y={[-25, 25]} tagOuter="div">
          <Img
            alt=" "
            fluid={data.placeholderImage.childImageSharp.fluid}
            imgStyle={{ objectFit: "cover" }}
            style={{ minHeight: "550px" }}
          />
        </Parallax>
      </div>
      <p className="parallax-text landing-page-header parallax-text-4 no-animation">
        What Our
        <br /> Students Are Saying
      </p>
    </div>
  )
}

export default Students