import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Parallax } from "react-scroll-parallax"

const DR = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "dr.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <div style={{ position: "relative" }}>
        <div
          style={{
            height: "800px",
            minWidth: "100%",
            zIndex: "-100",
            margin: "0 auto",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <Parallax y={[-25, 25]} tagOuter="div">
            <Img
              alt=" "
              fluid={data.placeholderImage.childImageSharp.fluid}
              imgStyle={{ objectFit: "cover" }}
              style={{ minHeight: "800px" }}
            />
          </Parallax>
        </div>
        <p className="parallax-text parallax-text-2 parallax-text-multi-line landing-page-header no-animation">
          The Dominican Republic
        </p>
      </div>

      <div className="section white">
        <div className="container row center-align">
          <div className="col s12 left-align">
            <p className="landing-page-content">
              Filmed on location,
              <em className="itl">
                Beyond the Block: The Dominican Republic
              </em>{" "}
              is an educational travel documentary that profiles the everyday
              lives of children living in a rural community in the Dominican
              Republic. Themes include: education, the concept of solidarity,
              distribution of resources, and realities of rural living.
            </p>
            <p className="landing-page-content">
              The target audience for our video learning tools are students
              at-risk of school dropout in grades 6-12. Through viewing the
              videos and engaging in accompanying lessons and activities,
              students will work towards improving their global awareness,
              building their intellectual curiosity and critical-thinking
              skills, and increasing their motivation to complete high school
              and post-secondary education.
            </p>
          </div>
          <div className="col s12">
            <iframe
              className="travel-video"
              width="60%"
              height="400"
              src="https://www.youtube.com/embed/EI4C9PtcKus"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen={true}
              title="Beyond the Block: The Dominican Republic"
            ></iframe>
          </div>
        </div>
      </div>
    </>
  )
}

export default DR
