import React from "react"

const StudentsContent = () => {

  return (
    <div className="section white">
      <div className="container row">
        <div className="col s12 l6 mt2">
          <i className="fa fa-quote-left fa-3x" aria-hidden="true"></i>
          <q>
            We can <strong>make the world better</strong> by treating people
            equally.
          </q>
          <br />
          <br />
          <i className="fa fa-quote-left fa-3x" aria-hidden="true"></i>
          <q>
            I will remember to be grateful for what I have. I'll remember to
            <strong> think of other people, then myself</strong>.
          </q>
          <br />
          <br />
          <i className="fa fa-quote-left fa-3x" aria-hidden="true"></i>
          <q>
            Appreciate what you have...
            <strong> take advantage of all opportunities in life</strong>.
          </q>
          <br />
          <br />
        </div>
        <div className="col s12 l6 mt2 mb-sm">
          <i className="fa fa-quote-left fa-3x" aria-hidden="true"></i>
          <q>
            The video made me think about how here kids ditch, give up easily,
            and don't get how they have a chance to make a difference. It made
            me wanna
            <strong> try harder to graduate and succeed</strong> to later help
            the kids in Haiti.
          </q>
          <br />
          <br />
          <i className="fa fa-quote-left fa-3x" aria-hidden="true"></i>
          <q>
            I have learned to<strong> never drop out of school</strong>.
          </q>
        </div>
      </div>
    </div>
  )
}

export default StudentsContent
