import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Parallax } from "react-scroll-parallax"
import Container from "./Container"
import Row from "./Row"

const Donate = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "students_2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div id="donate">
      <div style={{ position: "relative" }}>
        <div
          style={{
            height: "500px",
            minWidth: "100%",
            zIndex: "-100",
            margin: "0 auto",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <Parallax y={[-25, 25]} tagOuter="div">
            <Img
              alt=" "
              fluid={data.placeholderImage.childImageSharp.fluid}
              imgStyle={{ objectFit: "cover" }}
              style={{ minHeight: "550px" }}
            />
          </Parallax>
        </div>
        <p className="parallax-text parallax-text-3 landing-page-header no-animation">
          Donate
        </p>
      </div>
      <Container addClass="white">
        <Row>
          <br />
          <div className="col s12 center-align">
            <p className="page-content">
              Please consider making a tax-deductible donation to Beyond the
              Block! As a 501(c)3 nonprofit, your donations go right back into
              the organization, helping to create educational travel videos and
              curriculum for students who are at risk of school dropout.
            </p>
          </div>

          <Row>
            <div className="col s12 center-align">
              <h3 className="page-sub-head">
                Make a One Time or Recurring Online Donation
              </h3>
            </div>
            <div className="col s12 m3 push-m9 center-align">
              <form
                action="https://www.paypal.com/cgi-bin/webscr"
                method="post"
                target="_blank"
                className="paypal-form"
              >
                <input type="hidden" name="cmd" value="_s-xclick" />
                <input
                  type="hidden"
                  name="hosted_button_id"
                  value="KKTUP9ZW6DM92"
                />
                <input
                  type="image"
                  src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif"
                  border="0"
                  name="submit"
                  title="PayPal - The safer, easier way to pay online!"
                  alt="Donate with PayPal button"
                />
                <img
                  alt=""
                  border="0"
                  src="https://www.paypal.com/en_US/i/scr/pixel.gif"
                  width="1"
                  height="1"
                />
              </form>
            </div>
            <div className="col s12 m9 pull-m3">
              <p className="page-content">
                To make a secure donation of any amount via PayPal, please click
                the button to the right! You do not need a PayPal account in
                order to donate.
              </p>
            </div>
          </Row>

          <Row>
            <div className="col s12 center-align">
              <h3 className="page-sub-head">Sponsor A School Presentation</h3>
            </div>
            <div className="col s12 m3 push-m9 center-align">
              <form
                action="https://www.paypal.com/cgi-bin/webscr"
                method="post"
                target="_blank"
                className="paypal-form"
              >
                <input type="hidden" name="cmd" value="_s-xclick" />
                <input
                  type="hidden"
                  name="hosted_button_id"
                  value="R2SSPNZM7WY96"
                />
                <input
                  type="image"
                  src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif"
                  border="0"
                  name="submit"
                  title="PayPal - The safer, easier way to pay online!"
                  alt="Donate with PayPal button"
                />
                <img
                  alt=""
                  border="0"
                  src="https://www.paypal.com/en_US/i/scr/pixel.gif"
                  width="1"
                  height="1"
                />
              </form>
            </div>
            <div className="col s12 m9 pull-m3">
              <p className="page-content">
                Do you know of a school that could benefit from our services?
                You can arrange to sponsor a presentation at that school! Simply
                provide us with a contact name and number at the school (teacher
                or administrator). For a donation of $200 we will provide one
                classroom presentation or assembly of any size. The PayPal
                donation button to the right will prompt you to donate $200 and
                give you the space to write the school contact information.
              </p>
            </div>
          </Row>

          <Row>
            <div className="col s12 center-align">
              <h3 className="page-sub-head">Sponsor a Documentary</h3>
            </div>
            <div className="col s12 m3 push-m9 center-align">
              <form
                action="https://www.paypal.com/cgi-bin/webscr"
                method="post"
                target="_blank"
                className="paypal-form"
              >
                <input type="hidden" name="cmd" value="_s-xclick" />
                <input
                  type="hidden"
                  name="hosted_button_id"
                  value="B8JK7NNW87CSS"
                />
                <input
                  type="image"
                  src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif"
                  border="0"
                  name="submit"
                  title="PayPal - The safer, easier way to pay online!"
                  alt="Donate with PayPal button"
                />
                <img
                  alt=""
                  border="0"
                  src="https://www.paypal.com/en_US/i/scr/pixel.gif"
                  width="1"
                  height="1"
                />
              </form>
            </div>
            <div className="col s12 m9 pull-m3">
              <p className="page-content">
                Would you like to help Beyond the Block produce their next
                documentary? Though we are committed to low-budget media
                production, there are still costs associated with our
                documentaries, such as air travel, ground travel, equipment,
                technology, food, and accommodation. For a donation of $500, you
                will be listed as an Executive Producer of our next documentary
                and have your name appear in the credits! We will also send you
                a free copy of the documentary you helped fund! The PayPal
                donation button to the right will prompt you to donate $500. It
                will also give you the space to write any contact information
                you might have for a city, country, person, and/or organization
                we could visit. Though this may not be the place we end up
                visiting next, we will absolutely keep you updated on our
                communications with your contact!
              </p>
            </div>
          </Row>

          <Row>
            <div className="col s12 center-align">
              <h3 className="page-sub-head">Mail in a Donation</h3>
              <p className="page-content">
                You can send a check to our offices at:
              </p>
              <p className="page-content">
                <strong>
                  Beyond the Block, Inc.
                  <br /> 2622 19th Ave.
                  <br /> San Francisco, CA 94116
                </strong>
              </p>
              <p className="page-content">
                We will be sure to send you a receipt for your tax deductible
                donation!
              </p>
            </div>
          </Row>
        </Row>
      </Container>
    </div>
  )
}

export default Donate
