import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Parallax } from "react-scroll-parallax"

const Bosnia = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "bosnia.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <div style={{ position: "relative" }}>
        <div
          style={{
            height: "800px",
            minWidth: "100%",
            zIndex: "-100",
            margin: "0 auto",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <Parallax y={[-25, 25]} tagOuter="div">
            <Img
              alt=" "
              fluid={data.placeholderImage.childImageSharp.fluid}
              imgStyle={{ objectFit: "cover" }}
              style={{ minHeight: "800px" }}
            />
          </Parallax>
        </div>
        <p className="parallax-text parallax-text-2 landing-page-header no-animation travels-title-sm">
          Bosnia
        </p>
      </div>

      <div className="section white">
        <div className="container row center-align">
          <div className="col s12 left-align">
            <p className="landing-page-content">
              When our founder, Debbie, traveled to the Balkan Peninsula on a
              backpacking trip with her friend, she did not expect to make a
              video out of the trip. But she found herself so inspired by the
              lived experiences of the people she met there that she had to
              share the trip with her students. Through a reflection on the
              Balkan War, students will gain insight into the human suffering
              caused by violence and be encouraged to explore the connection
              between media, war, and the use of violence in their own lives.
              Themes include personal and national effects of war and violence,
              resiliency, and international relations.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Bosnia
