import React from "react"
import { Helmet } from "react-helmet"
import SEO from "../components/seo"

import Landing from "../components/Landing"
import LearningCommunity from "../components/LearningCommunity"
import LearningCommunityContent from "../components/LearningCommunityContent"
import Students from "../components/Students"
import StudentsContent from "../components/StudentsContent"
import Travels from "../components/Travels"
import Mission from "../components/Mission"
import Team from "../components/Team"
import Services from "../components/Services"
import Donate from "../components/Donate"
import Contact from "../components/Contact"
import Layout from "../components/Layout"

import "../components/Styles.css"

const IndexPage = () => {
  return (
    <>
      <Helmet>
        {/* Font Awesome */}
        <link
          rel="stylesheet"
          href="https://use.fontawesome.com/releases/v5.8.2/css/all.css"
          integrity="sha384-oS3vJWv+0UjzBfQzYUhtDYW+Pj2yciDJxpsK1OYPAYjqT085Qq/1cq5FLXAZQ7Ay"
          crossorigin="anonymous"
        />
        {/* Materialize Icons */}
        <link
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
          rel="stylesheet"
        />
        {/* Google Fonts */}
        <link
          href="https://fonts.googleapis.com/css?family=Oswald&display=swap"
          rel="stylesheet"
        />
        {/* MailChimp */}
        <link
          href="//cdn-images.mailchimp.com/embedcode/classic-081711.css"
          rel="stylesheet"
          type="text/css"
        />
      </Helmet>
      <SEO title="Home" />
      <Layout>
        <Landing />
        <LearningCommunity />
        <LearningCommunityContent />
        <Students />
        <StudentsContent />
        <Travels />
        <Mission />
        <Team />
        <Services />
        <Donate />
        <Contact />
      </Layout>
    </>
  )
}

export default IndexPage
