import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Rory = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "na.png" }) {
        childImageSharp {
          fixed(width: 312) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <div className="col s12 l6">
      <Img
        fixed={data.placeholderImage.childImageSharp.fixed}
        alt="Rory Olsen, MIPH, Board Member"
      />
      <h4 className="page-content-important">
        Rory Olsen, MIPH
        <br />
        Board Member
      </h4>

      {/* Rory Modal */}
      <a className="btn modal-trigger" href="#rory-modal">
        Rory's Bio
      </a>

      <div id="rory-modal" className="modal">
        <div className="modal-content">
          <h4>Rory Olsen, MIPH</h4>
          <p className="page-content">
            Rory Olsen enjoys working from the grassroots level upward. After
            graduating from University of San Diego in 2001 with a B.A. in
            International Relations, he moved to Botswana working on HIV/AIDS
            education and testing. Later, he moved to neighboring South Africa
            to continue HIV/AIDS awareness but focusing on orphaned and
            vulnerable children. This eventually led him to the Dominican
            Republic where he focused on environmental health and prenatal care.
            <br />
            <br />
            In 2006 Rory graduated from the University of Sydney with a Masters
            in International Public Health. After graduation he moved to the
            Northern Territory of Australia to work with Aboriginal Australians
            on the systemic issues associated with diabetes and heart disease.
            He has also spent time working on primary health care projects in
            rural India.
            <br />
            <br />
            Since 2009 Rory has resided in Los Angeles working in textile
            recycling. He was the Operations Manager for Eco Life—a green,
            environmentally friendly, eco-conscious, recycle/reuse organization
            focusing on eliminating textiles from the waste stream that
            ultimately ends up in our landfills. Currently, Rory is returning to
            his focus on public health and studying to become a nurse.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Rory
