import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Tony = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "tony.png" }) {
        childImageSharp {
          fixed(width: 156) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <div className="col s12 l6 bio-button-padding-extra">
      <Img
        fixed={data.placeholderImage.childImageSharp.fixed}
        alt="Tony Ribera, Ph.D., Board Member"
      />
      <h4 className="page-content-important board-title">
        Tony Ribera, Ph.D.
        <br />
        Board Member
      </h4>

      {/* Tony Modal */}
      <a className="btn modal-trigger" href="#tony-modal">
        Tony's Bio
      </a>

      <div id="tony-modal" className="modal">
        <div className="modal-content">
          <h4>Dr. Tony Ribera</h4>
          <Img
            fixed={data.placeholderImage.childImageSharp.fixed}
            alt="Tony Ribera, Ph.D., Board Member"
            style={{ float: "left" }}
            imgStyle={{ paddingRight: "20px" }}
          />
          <p className="page-content">
            Tony Ribera is the Director of Educational Assessment at Marian
            University, Indianapolis. He has a Ph.D. in Higher Education and
            Student Affairs from Indiana University in Bloomington, IN.
            <br />
            <br />
            Originally from San Francisco, Tony earned a B.A. in English and an
            M.A. in Higher Education from the University of Redlands in
            Redlands, CA. There, Tony also worked in community service learning
            where he served as director of a child mentoring program geared
            toward promoting the social and academic success of at-risk youth.
            <br />
            <br />
            Tony formerly served as Director of Assessment at Rose-Hulman
            Institute of Technology and as a Project Associate in the Indiana
            University Center for Postsecondary Research and as the Director of
            Program Evaluation at the Indiana University School of Medicine. He
            is interested in applying his knowledge of qualitative and
            quantitative research to help Beyond the Block better understand the
            program’s impact on student learning outcomes.
            <br />
            <br />
            Tony has always been artistically inclined as well. He is the
            creator of Beyond the Block’s logo!
          </p>
        </div>
      </div>
    </div>
  )
}

export default Tony
