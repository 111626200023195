import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Row from "./Row"
import { Parallax } from "react-scroll-parallax"
import ARModal from "./ARModal"

const Mission = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "mission.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div id="mission">
      <div style={{ position: "relative" }}>
        <div
          style={{
            height: "500px",
            minWidth: "100%",
            zIndex: "-100",
            margin: "0 auto",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <Parallax y={[-35, 35]} tagOuter="div">
            <Img
              alt=" "
              fluid={data.placeholderImage.childImageSharp.fluid}
              imgStyle={{ objectFit: "cover", objectPosition: "right" }}
              style={{ minHeight: "550px" }}
              className="mission-photo"
            />
          </Parallax>
        </div>
        <p className="parallax-text parallax-text-3 landing-page-header no-animation">
          Mission
        </p>
      </div>

      <Row addClass="white">
        <br />
        <div className="col s1 m2" />
        <div className="col s10 m8 center-align">
          <h3 className="page-sub-head">Identifying the Problem:</h3>
          <h4 className="page-content-important itl">
            A lack of diversity education materials designed specifically for
            students of color.
          </h4>
          <p className="page-content left-align">
            During her time working with students at risk of dropout in LAUSD,
            Dr. Ribera met with countless parents from Mexico and Central
            America who experienced severe intercultural estrangement from their
            children. A common refrain heard from parents was:
            <strong> “If only they could see where I was from, they would understand
              how lucky they are to be able to attend school here.” </strong>
             She also met with students who could barely travel beyond the block
            their homes were located on due to the risk of crossing into rival
            gang territory. Others simply had no desire or method to see the
            world outside of their neighborhood. Beyond the Block was inspired
            by these students and their parents.
          </p>
          <p className="page-content left-align">
            Dr. Ribera began to brainstorm how she could create a virtual travel
            experience for students that would introduce them to new and
            different cultures that they might otherwise not have the
            opportunity to know. Her hope was the same as their parents: that as
            the students were introduced to the world that lay beyond their
            neighborhoods, they would be able to recognize the relative
            privilege they possess compared to the majority of children in the
            world by virtue of simply residing in the United States. As Dr.
            Ribera pursued this idea, she saw
            <strong> a gap in diversity education learning materials specifically
              designed for students of color.</strong>
          </p>
          <p className="page-content left-align">
            Diversity education, a common curricular requirement in
            majority-white private K-12 schools and public and private
            universities, has well-established cognitive and social benefits
            (Alger, 1998; Gurin et al., 2002, 2004; Nelson Laird, 2005; D. Smith
            &amp; B. Smith, 2009). However the involvement of students of color
            with diversity is typically reduced to their usefulness to
            majority-white institutions in adding population variance.
            Consequently, little to no emphasis is put on understanding the
            importance of curricular and social diversity to students of color
            in either teacher preparation programs or in the classroom itself
            (Worley, 2011).
            <strong> Students of color, especially those in majority-minority,
              underfunded urban schools, are then deprived of the immense
              benefits a diversity education could have in such a high-need
              setting.
            </strong>
          </p>

          <h3 className="page-sub-head">Addressing the Need:</h3>
          <h4 className="page-content-important itl">
            We create diversity education materials specifically designed for
            urban students of color who are at risk of school dropout.
          </h4>
          <p className="page-content left-align">
            After extensively reviewing the research on diversity education
            supplemented with her own experience working with students at risk
            of school dropout, Dr. Ribera developed the mission of Beyond the
            Block:
            <em> to improve global awareness, increase intellectual curiosity,
              develop critical-thinking skills, and encourage completion of high
              school and post-secondary education in at-risk youth through the
              production of educational travel documentary videos, an
              accompanying curriculum, and other educational opportunities and
              materials.</em>
          </p>

          {/* Annual Reports Modal */}
          <ARModal />
        </div>
        <div className="col s1 m2" />
      </Row>
    </div>
  )
}

export default Mission
