import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { Parallax } from "react-scroll-parallax"
import Row from "./Row"

const Services = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "students_3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div id="services">
      <div style={{ position: "relative" }}>
        <div
          style={{
            height: "500px",
            minWidth: "100%",
            zIndex: "-100",
            margin: "0 auto",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <Parallax y={[-30, 30]} tagOuter="div">
            <Img
              alt=" "
              fluid={data.placeholderImage.childImageSharp.fluid}
              imgStyle={{ objectFit: "cover" }}
              style={{ minHeight: "550px" }}
            />
          </Parallax>
        </div>
        <p className="parallax-text parallax-text-3 landing-page-header no-animation">
          Services
        </p>
      </div>

      <Row addClass="white">
        <br />
        <div className="col s1 m2"></div>
        <div className="col s10 m8 center-align">
          <p className="page-content left-align">
            We are excited to provide free online global awareness courses for
            students. Through these courses they will be able to view our
            educational travel documentaries and complete accompanying
            activities. Our CEO is currently providing personalized feedback to
            EVERY student who is enrolled in the course. Please visit us at
            <a
              href="https://btbacademy.beyondtheblock.org"
              target="_blank"
              rel="noopener noreferrer"
            >&nbsp;BTB Academy </a>
             to learn more and enroll!
          </p>
          <p className="page-content left-align">
            In addition to our free classes for students, we provide in-person
            presentations, curriculum implementation, consultation, and coaching
            on a fee basis. Our global awareness curriculum is our main product,
            but we also provide professional developments for staff in diversity
            education, trauma-sensitive practices, and other related topics. We
            will offer online classes specifically geared towards educators
            soon! Please <Link to="#contact"> contact us</Link> to discuss your
            school’s needs!
          </p>
          <p
            className="page-content left-align"
            style={{ marginBottom: "40px" }}
          >
            Lastly, we are looking for a place to film our next documentary! We
            will produce a free promotional video for your organization or
            school in exchange for your help in allowing us to get to know and
            understand your community. <Link to="#contact">Contact us</Link> if
            you are interested!
          </p>
        </div>
        <div className="col s1 m2"></div>
      </Row>
    </div>
  )
}

export default Services
