import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Katie = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "katie.jpg" }) {
        childImageSharp {
          fixed(width: 133) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <div className="col s12 l6">
      <Img
        fixed={data.placeholderImage.childImageSharp.fixed}
        alt="Katie Moody, M.S., Board Member"
      />
      <h4 className="page-content-important board-title">
        Katie Moody, M.S.
        <br />
        Board Member
      </h4>

      {/* Katie Modal */}
      <a className="btn modal-trigger" href="#katie-modal">
        Katie's Bio
      </a>

      <div id="katie-modal" className="modal">
        <div className="modal-content">
          <h4>Katie Moody, M.S.</h4>
          <Img
            fixed={data.placeholderImage.childImageSharp.fixed}
            alt="Katie Moody, M.S., Board Member"
            style={{ float: "left" }}
            imgStyle={{ paddingRight: "20px" }}
          />
          <p className="page-content">
            Katie Moody graduated with her Masters in Regulatory Economics in
            2003. Straight after graduation she moved to Los Angeles, California
            where she began work at Southern California Edison, a large
            electricity company in the Los Angeles area. At SCE she worked in
            various areas including energy efficiency and renewable energy. She
            started at SCE as an intern and within five years was a senior
            project manager working on policy for the company and is currently
            the Director of eMobility and Building Electrification.
            <br />
            <br />
            While in Los Angeles, Katie became very involved in her community of
            Pasadena. She volunteered for the Junior Chamber of Commerce and
            YWCA and held numerous board positions, including president of the
            Junior Chamber. Katie's civic work culminated by being nominated to
            serve on Pasadena's Commission for the Status of Women as a
            Commissioner to work on issues important to the women of Pasadena.
            In 2009, Katie was named "40 under 40" young people to watch in
            Pasadena and a Women of the Year by the San Gabriel Valley YWCA for
            both her work at SCE and philanthropic activities.
            <br />
            <br />
            In 2010, Katie decided to take some time off of work and
            volunteering to travel the world. She travelled for 10 months to
            over 20 countries in Asia, Africa, and South America. She is so
            excited to be a part of Beyond the Block because she truly believes
            that people can open their lives, ideas, and hearts when they learn
            how other people live. Through exploring new frontiers we learn more
            about what makes others tick, how to be more accepting, and finally
            more about ourselves and how to become better people.
            <br />
            <br />
            As a Board Member, Katie is excited to share her business, finance,
            and project management expertise with Beyond the Block, as well as
            her extensive knowledge on world travel.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Katie
