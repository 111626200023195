import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Debbie = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "debbie.png" }) {
        childImageSharp {
          fixed(width: 178) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <>
      <h3 className="page-sub-head">Meet Our Founder</h3>
      <br />
      <div className="col s12">
        <Img
          fixed={data.placeholderImage.childImageSharp.fixed}
          alt="Debbie Ribera, Ph.D., Founder and CEO"
        />
        <h4 className="page-content-important">
          Debbie Ribera, Ph.D.
          <br />
          Founder and CEO
        </h4>

        {/* Debbie Modal */}
        <a className="btn modal-trigger" href="#debbie-modal">
          Debbie's Bio
        </a>

        <div id="debbie-modal" className="modal">
          <div className="modal-content">
            <h4>Dr. Deborah Ribera</h4>
            <Img
              fixed={data.placeholderImage.childImageSharp.fixed}
              alt="Debbie Ribera, Ph.D., Founder and CEO"
              style={{ float: "left" }}
              imgStyle={{ paddingRight: "20px" }}
            />
            <p className="page-content">
              As founder and CEO of Beyond the Block, I have written, produced,
              and directed three educational travel documentaries. I have
              developed a curriculum to accompany the videos based on critical,
              anti-racist pedagogy and cultural theory. Using the documentaries
              and curriculum, I travel to schools, making interactive
              presentations about global awareness. I also work with the Board
              of Directors on fundraising, marketing, program development, and
              everything else. I am proud to say that we have reached well over
              3,000 students through Beyond the Block.
              <br />
              <br />
              I earned my doctorate in American Culture Studies at the School of
              Cultural and Critical Studies at Bowling Green State University
              (BGSU). My dissertation, titled (Re)Presentation: An Affective
              Exploration of Ethnographic Documentary Film Production, explored
              the intersections of affect theory, documentary media studies,
              feminist ethics, and critical ethnography. Through BGSU, I taught
              undergraduate classes on Cultural Pluralism and Ethnic Studies. I
              also developed a course cross-listed in American Culture Studies
              and Ethnic Studies called Interpretations of Urban Gang Culture.
              <br />
              <br />
              I earned my M.S. in School-based Family Counseling from California
              State University at Los Angeles. I hold a Pupil Personnel Services
              Credential in School Counseling with advanced authorization in
              Child Welfare and Attendance and am a registered Marriage and
              Family Therapist (License #79648).
              <br />
              <br />
              Over the past 14 years, I have worked in various capacities with
              juvenile offenders, newly immigrated families, children of
              incarcerated parents, and students at risk of school dropout. I
              was a dropout prevention counselor for Los Angeles Unified School
              District and a school counselor and Marriage and Family Therapist
              Intern for schools in East Palo Alto and Oakland, CA. My students
              in LA inspired me to come up with the idea of Beyond the Block and
              my students in Northern California kept me inspired to pursue the
              goal of making Beyond the Block a reality. The children and adults
              I encounter through my travels continue to inspire me to grow and
              become a better person. It is a privilege to be able to share that
              potential for growth through Beyond the Block!
              <br />
              <br />
              To contact Debbie, you can send her an e-mail at: &nbsp;
              <a href="mailto:debbie@beyondtheblock.org">
                debbie@beyondtheblock.org
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Debbie
