import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Parallax } from "react-scroll-parallax"

const Haiti = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "haiti.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <div style={{ position: "relative" }}>
        <div
          style={{
            height: "800px",
            minWidth: "100%",
            zIndex: "-100",
            margin: "0 auto",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <Parallax y={[-25, 25]} tagOuter="div">
            <Img
              alt=" "
              fluid={data.placeholderImage.childImageSharp.fluid}
              imgStyle={{ objectFit: "cover" }}
              style={{ minHeight: "800px" }}
            />
          </Parallax>
        </div>
        <p className="parallax-text parallax-text-2 landing-page-header no-animation travels-title-sm">
          Haiti
        </p>
      </div>

      <div className="section white">
        <div className="container row center-align">
          <div className="col s12 left-align">
            <p className="landing-page-content">
              This documentary features the stories of children and young adults
              living in Port-au-Prince who understand life in Haiti not from the
              evening news but from their own first-hand experience. What were
              their experiences in the devastating 2010 earthquake? How do they
              view their lives and culture? What do they make of US military and
              aid involvement in Haiti? What are their dreams and goals for
              their futures? The stories of pain, resilience, and hope will not
              just inspire viewers, but also challenge them to critically
              examine traditional media representations of Haiti as well as how
              American policies influence culture abroad. Themes include:
              education, resiliency, distribution of resources, and
              international relations.
            </p>
          </div>
          <div className="col s12">
            <iframe
              className="travel-video"
              width="60%"
              height="400"
              src="https://www.youtube.com/embed/U_qWx82h5dY"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen={true}
              title="Beyond the Block: Haiti"
            ></iframe>
          </div>
        </div>
      </div>
    </>
  )
}

export default Haiti
