import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Parallax } from "react-scroll-parallax"

const Landing = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "picture_12.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <div
        style={{
          height: "500px",
          minWidth: "100%",
          zIndex: "-100",
          margin: "0",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Parallax y={[-30, 35]} tagOuter="div">
          <Img
            alt=" "
            fluid={data.placeholderImage.childImageSharp.fluid}
            loading="eager"
            imgStyle={{ objectFit: "cover" }}
            style={{ minHeight: "500px" }}
          />
        </Parallax>
      </div>
      <p className="parallax-text parallax-text-title landing-page-header">
        We Bring the
        <br /> World Into the Classroom
      </p>

      {/* Intro Content */}
      <div className="section white">
        <div className="container row">
          <div className="col s12 l6">
            <div className="hide-on-med-and-down"></div>
            <p className="landing-page-content neg-mt1 mb2">
              Beyond the Block is a
              <strong> 501(c)3 educational nonprofit </strong>
              organization that helps students:
            </p>
          </div>
          <div className="col s12 l6">
            <ul className="landing-list page-content neg-mt1 mb2">
              <li>
                <i
                  className="fa fa-angle-double-right fa-3x"
                  aria-hidden="true"
                ></i>
                Improve their global awareness
              </li>
              <li>
                <i
                  className="fa fa-angle-double-right fa-3x"
                  aria-hidden="true"
                ></i>
                Increase their intellectual curiosity
              </li>
              <li>
                <i
                  className="fa fa-angle-double-right fa-3x"
                  aria-hidden="true"
                ></i>
                Develop their critical-thinking skills
              </li>
              <li>
                <i
                  className="fa fa-angle-double-right fa-3x"
                  aria-hidden="true"
                ></i>
                Complete their high school and post-secondary education
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default Landing
