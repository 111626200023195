import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Leo = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "na.png" }) {
        childImageSharp {
          fixed(width: 312) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <div className="col s12 l6 bio-button-padding-extra">
      <Img
        fixed={data.placeholderImage.childImageSharp.fixed}
        alt="Leonel Jaimez, Board Member"
      />
      <h4 className="page-content-important">
        Leonel Jaimez
        <br />
        Board Member
      </h4>

      {/* Starlit Modal */}
      <a className="btn modal-trigger" href="#leo-modal">
        Leonel's Bio
      </a>

      <div id="leo-modal" className="modal">
        <div className="modal-content">
          <h4>Leonel Jaimez</h4>
          {/* <Img
              fixed={data.placeholderImage.childImageSharp.fixed}
              alt="Leo Jaimez, Board Member"
              style={{ float: "left" }}
              imgStyle={{ paddingRight: "20px" }}
            /> */}
          <p className="page-content">
            Leo graduated from the University of Redlands with a major in
            Economic and minor in Business Administration. His senior thesis
            focused on researching the current economic development proposals in
            his hometown of East Palo Alto. Leo’s passion, experience, and
            commitment to supporting low-income urban communities informs his
            contributions to the BTB Board of Directors.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Leo