import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Parallax } from "react-scroll-parallax"
import SubscribeForm from "./SubscribeForm"

const Contact = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "dr_2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div id="contact">
      <div style={{ position: "relative" }}>
        <div
          style={{
            height: "500px",
            minWidth: "100%",
            zIndex: "-100",
            margin: "0 auto",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <Parallax y={[-25, 25]} tagOuter="div">
            <Img
              alt=" "
              fluid={data.placeholderImage.childImageSharp.fluid}
              imgStyle={{ objectFit: "cover", objectPosition: "left" }}
              style={{ minHeight: "550px" }}
            />
          </Parallax>
        </div>
        <p className="parallax-text landing-page-header no-animation parallax-text-4">
          We'd love to <br />
          hear from you!
        </p>
      </div>

      <div className="container">
        <div className="row">
          <br />
          <div className="col s12" />
        </div>
        <div className="row">
          <div className="col s12 m5 center-align">
            <h3 className="page-sub-head">Contact Us</h3>
            <h4>Address</h4>
            <p className="page-content">
              Beyond the Block, Inc.
              <br />
              2622 19th Ave.
              <br />
              San Francisco, CA 94116
            </p>
            <h4>Email</h4>
            <a href="mailto:info@beyondtheblock.org">
              <p className="page-content">info@beyondtheblock.org</p>
            </a>
            <a href="mailto:debbie@beyondtheblock.org">
              <p className="page-content">debbie@beyondtheblock.org</p>
            </a>
            <h4>Phone</h4>
            <p className="page-content">
              415-294-1BTB
              <br />
              ​(415-294-1282)
            </p>
          </div>
          <div className="col s12 m2" />
          <div className="col s12 m5">
            <h3 className="page-sub-head center-align">
              Join Our Mailing List
            </h3>
            <SubscribeForm />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
