import React from "react"
import DR from "./DR"
import Haiti from "./Haiti"
import Bosnia from "./Bosnia"
import Alaska from "./Alaska"

const Travels = () => {
    return (
      <div id="travels">
        <DR />
        <Haiti />
        <Bosnia />
        <Alaska />
      </div>
    )
}

export default Travels