import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Starlit = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "na.png" }) {
        childImageSharp {
          fixed(width: 312) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <div className="col s12 l6 bio-button-padding-extra">
      <Img
        fixed={data.placeholderImage.childImageSharp.fixed}
        alt="Starlit Hill, Ph.D., Board Secretary"
      />
      <h4 className="page-content-important">
        Starlit Hill, Ph.D.
        <br />
        Board Secretary
      </h4>

      {/* Starlit Modal */}
      <a className="btn modal-trigger" href="#starlit-modal">
        Starlit's Bio
      </a>

      <div id="starlit-modal" className="modal">
        <div className="modal-content">
          <h4>Starlit Hill, Ph.D.</h4>
          {/* <Img
              fixed={data.placeholderImage.childImageSharp.fixed}
              alt="Starlit Hill, Ph.D., Board Secretary"
              style={{ float: "left" }}
              imgStyle={{ paddingRight: "20px" }}
            /> */}
          <p className="page-content">
            Starlit Hill has a Ph. D. in American Culture Studies from Bowling
            Green State University in Bowling Green, OH, where she previously
            taught introductory women’s studies and cultural pluralism courses.
            Her research focused on the gendered authority experiences of
            juvenile corrections officers.
          </p>
          <p className="page-content">
            Originally from Vado, NM, Starlit earned her Bachelor’s and Master’s
            degrees in Criminal Justice from New Mexico State University in Las
            Cruces, NM. Her experience with juvenile justice system work
            included positions as a youth care specialist at a boys’ facility
            and a community educator for a first-offender diversion program. Her
            passion for improving the lives of at-risk youth also informed and
            was informed by her work at a girls’ group home and at a juvenile
            assessment and reporting center.
          </p>
          <p className="page-content">
            Starlit is currently enjoying
            taking care of her three-year old and serves as Membership Officer
            for her local mom’s group. As a Beyond the Block board member,
            Starlit hopes to help secure grant funding and share her knowledge
            and experiences.  She is enthusiastic about serving on the board
            because Beyond the Block resonates with her passion for community
            service and non-profit work, particularly that related to at-risk
            youth, and merges with her other academic work towards diversity
            education.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Starlit
