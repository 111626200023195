import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const LearningCommunityContent = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "btb-academy.png" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className="section white">
      <div className="container row center-align">
        <div className="col s12 l6 mb2">
          <h2 className="landing-page-header">
            Learn more about
            <br /> what we do
          </h2>
          <iframe
            width="100%"
            height="350"
            src="https://www.youtube.com/embed/WeuTmreDHT4"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen={true}
            title="About Beyond the Block"
          ></iframe>
        </div>
        <div className="col s12 l6 mb2">
          <h2 className="landing-page-header">
            Take Free Courses Through
            <br /> BTB Academy
          </h2>
          <a
            href="https://btbacademy.beyondtheblock.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Img
              alt="BTB Academy"
              fluid={data.placeholderImage.childImageSharp.fluid}
              className="academy-img"
              imgStyle={{ objectFit: "fill" }}
            />
          </a>
        </div>
      </div>
    </div>
  )
}

export default LearningCommunityContent
