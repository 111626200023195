import React, { Component } from "react"

class SubscribeForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      emailValue: "",
      fNameValue: "",
      lNameValue: "",
    }
  }

  onHandleSubmit() {
    setTimeout(() => {
      this.setState({
        emailValue: "",
        fNameValue: "",
        lNameValue: "",
      })
    },
      1000
    )
  }

  render() {
    return (
      // Begin MailChimp Signup Form
      <div id="mc_embed_signup">
        <form
          action="//beyondtheblock.us1.list-manage.com/subscribe/post?u=3b1e304250e3bc8af66f973fb&amp;id=f1eb3dd3c4"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_blank"
          noValidate
        >
          <div id="mc_embed_signup_scroll" />
          <div className="indicates-required">
            <span className="asterisk">*</span> indicates required
          </div>
          <div className="mc-field-group">
            <label htmlFor="mce-EMAIL">
              Email Address <span className="asterisk">*</span>
            </label>
            <input
              type="email"
              value={this.state.emailValue}
              onChange={e => {
                this.setState({ emailValue: e.target.value })
              }}
              name="EMAIL"
              className="required email"
              id="mce-EMAIL"
            />
          </div>

          <div className="mc-field-group">
            <label htmlFor="mce-FNAME">
              First Name <span className="asterisk">*</span>
            </label>
            <input
              type="text"
              value={this.state.fNameValue}
              onChange={e => {
                this.setState({ fNameValue: e.target.value })
              }}
              name="FNAME"
              className="required"
              id="mce-FNAME"
            />
          </div>

          <div className="mc-field-group">
            <label htmlFor="mce-LNAME">
              Last Name <span className="asterisk">*</span>
            </label>
            <input
              type="text"
              value={this.state.lNameValue}
              onChange={e => {
                this.setState({ lNameValue: e.target.value })
              }}
              name="LNAME"
              className="required"
              id="mce-LNAME"
            />
          </div>

          <div id="mce-responses" className="clear">
            <div className="response display-none" id="mce-error-response" />
            <div className="response display-none" id="mce-success-response" />
          </div>

          {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
          <div className="form-validation">
            <input
              type="text"
              name="b_3b1e304250e3bc8af66f973fb_f1eb3dd3c4"
              tabIndex="-1"
              value=""
            />
          </div>
          <div className="clear">
            <input
              type="submit"
              value="SUBSCRIBE"
              name="subscribe"
              id="mc-embedded-subscribe"
              className="sub-btn"
              onClick={() => this.onHandleSubmit()}
            />
          </div>
        </form>
        <p className="itl">
          If you are using Gmail, please check your Promotions folder for our email communications.
        </p>
      </div>
    )
  }
}

export default SubscribeForm
