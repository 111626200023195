import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Ang = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "na.png" }) {
        childImageSharp {
          fixed(width: 312) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <>
      <div className="col s12 l6 bio-button-padding-extra">
        <Img
          fixed={data.placeholderImage.childImageSharp.fixed}
          alt="Angela Rivieccio, Board Treasurer"
        />
        <h4 className="page-content-important">
          Angela Rivieccio
          <br />
          Board Treasurer
        </h4>

        {/* Ang Modal */}
        <a className="btn modal-trigger" href="#ang-modal">
          Angela's Bio
        </a>

        <div id="ang-modal" className="modal">
          <div className="modal-content">
            <h4>Angela Rivieccio</h4>
            <p className="page-content">
              Angela is the Senior Director of Inventory Management at Old Navy.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Ang
