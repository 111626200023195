import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Parallax } from "react-scroll-parallax"

const Alaska = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "alaska.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <div style={{ position: "relative" }}>
        <div
          style={{
            height: "800px",
            minWidth: "100%",
            zIndex: "-100",
            margin: "0 auto",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <Parallax y={[-25, 25]} tagOuter="div">
            <Img
              alt=" "
              fluid={data.placeholderImage.childImageSharp.fluid}
              imgStyle={{ objectFit: "cover" }}
              style={{ minHeight: "800px" }}
            />
          </Parallax>
        </div>
        <p className="parallax-text parallax-text-2 landing-page-header no-animation travels-title-sm">
          Alaska
        </p>
      </div>

      <div className="section white">
        <div className="container row center-align">
          <div className="col s12 left-align">
            <p className="landing-page-content">
              Coming soon: <br />
              <br /> <em className="itl">Beyond the Block: Nulato, Alaska</em>.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Alaska
